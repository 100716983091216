import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Link,
  Stack,
} from '@chakra-ui/react';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';
import usePagedList from 'shared/src/hooks/usePagedList';
import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import useQueryDebounce from 'shared/src/hooks/useQueryDebounce';
import {
  offerListViewParamsFactory,
  VIEW_TYPES,
} from 'shared/src/helpers/offerListViewParams';

import LoyaltyMetricsWidget from 'web-react-ui/src/components/metrics/LoyaltyMetricsWidget';
import Business from 'web-react-ui/src/types/Business.interface';
import Input from 'web-react-ui/src/components/elements/Input';
import Dropdown from 'web-react-ui/src/components/modules/Dropdown';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';
import { AutoPagination } from 'web-react-ui/src/chakra/Pagination';
import Offer from 'web-react-ui/src/types/Offer.interface';
import OffersListTable from 'web-react-ui/src/chakra/offers/OffersListTable';
import { usePlanIncludesLoyalty } from '../../hooks/usePlan';
import client from '../../services/client';
import { strings } from '../../i18n';
import propertyResolver from '../../services/propertyResolver';
import MyLocationSelector from './MyLocationsSelector';
import PromoMetricsWidget from './PromoMetricsWidget';

const ITEMS_PER_PAGE = 20;

const fetchOffers = ({
  query,
  locationId,
  view,
  businessId,
  offerType,
}: {
  query?: string;
  locationId?: string;
  view?: string;
  businessId: string;
  offerType: string;
}) => {
  if (locationId === undefined) return false;
  const property = propertyResolver();
  const params: Record<string, any> = {
    query,
    limit: ITEMS_PER_PAGE,
  };
  if (offerType === 'loyalty') {
    params.flags = ['punchcard'];
  } else {
    params.flags = ['!punchcard'];
  }

  return client.properties
    .for(property.id)
    .businesses.for(businessId)
    .locations.for(locationId)
    .offers.list({
      ...params,
      ...offerListViewParamsFactory(view),
    });
};

const buildHref = (offer: Offer) =>
  `/business/${offer.businessId}/offers/${offer.id}`;

const Offers = ({
  business,
  offerType,
  hideBusiness,
}: {
  business: Business;
  offerType: string;
  hideBusiness: boolean;
}): JSX.Element => {
  const [view, setView] = useQuerystringParam('view', VIEW_TYPES.live, {
    squash: true,
  });
  const [locationId, setLocationId] = useQuerystringParam('location', null, {
    squash: true,
  });
  const [query, debouncedQueryHandler] = useQueryDebounce(
    view,
    business,
    locationId
  );
  const offersList = usePagedList(fetchOffers, {
    query,
    locationId,
    view,
    businessId: business.id,
    offerType,
  });
  const planIncludesLoyalty = usePlanIncludesLoyalty();
  const handleViewChange = (e: string) => {
    setView(e);
  };
  const options: Record<string, any>[] = [
    {
      key: 1,
      text: strings('dashboard.screen.offers.new'),
      value: VIEW_TYPES.new,
    },
    {
      key: 2,
      text: strings('dashboard.screen.offers.live'),
      value: VIEW_TYPES.live,
    },
    {
      key: 3,
      text: strings('dashboard.screen.offers.expiringSoon'),
      value: VIEW_TYPES.expiring,
    },
    {
      key: 4,
      text: strings('dashboard.screen.offers.expired'),
      value: VIEW_TYPES.expired,
    },
    {
      key: 5,
      text: strings('dashboard.screen.offers.draft'),
      value: VIEW_TYPES.disabled,
    },
  ];

  const addOfferText =
    offerType === 'loyalty'
      ? 'Add Punch Card'
      : strings('ui.component.appSidebar.addOffer');

  const loyaltyWarning = offerType === 'loyalty' && !planIncludesLoyalty;

  return (
    <Box>
      <ViewHeader title={offerType}>
        <Button
          as={NavLink}
          to={`/business/${business.id}/${offerType}/create`}
          colorScheme="blue"
          variant="solid"
          hidden={loyaltyWarning}
        >
          <BiPlus /> {addOfferText}
        </Button>
      </ViewHeader>
      <Alert status="warning" mb={8} hidden={!loyaltyWarning}>
        <AlertIcon />
        <Stack>
          <AlertTitle>Current Plan Does Not Support Loyalty Offers</AlertTitle>
          <AlertDescription>
            The plan on this account does not include Loyalty features. Punch
            cards creates while on a previous plan remain active, but cannot be
            changed in any way.{' '}
            <Link as={RouterLink} fontWeight="bold" to="./plans">
              Upgrade Plan
            </Link>{' '}
            to add or edit your Loyalty offers.
          </AlertDescription>
        </Stack>
      </Alert>
      {offerType === 'promotions' && (
        <PromoMetricsWidget businessId={business.id} locationId={locationId} />
      )}
      {offerType === 'loyalty' && (
        <LoyaltyMetricsWidget
          businessId={business.id}
          locationId={locationId}
        />
      )}
      <Flex alignItems="center" flexDir={['column', 'row']} mb={6}>
        <Box minW={['100%', '60%']}>
          <Input
            placeholder={strings('dashboard.screen.offers.findOffer')}
            fluid
            icon="search"
            defaultValue={query}
            onChange={(e: any) => debouncedQueryHandler(e.target.value)}
            className="fg2"
          />
        </Box>
        <Flex
          justifyContent={['space-between', null]}
          width="100%"
          mt={['16px', 0]}
        >
          <Box mr={2} ml={[0, 2]} width="50%">
            {/* @ts-ignore */}
            <MyLocationSelector
              value={locationId}
              onChange={(id: string) => setLocationId(id)}
            />
          </Box>
          <Box width="50%">
            <Dropdown
              selection
              fluid
              onChange={(e: any, { value }: { value: string }) =>
                handleViewChange(value)
              }
              options={options}
              value={view}
            />
          </Box>
        </Flex>
      </Flex>
      <Box border="1px" borderColor="gray.200">
        <OffersListTable
          offersList={offersList}
          noOffersString={strings('dashboard.screen.offers.noOffers')}
          buildHref={buildHref}
          hideBusiness={hideBusiness}
        />
      </Box>
      <Box py={6}>
        <AutoPagination pagedList={offersList} />
      </Box>
    </Box>
  );
};

export default Offers;
