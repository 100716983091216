import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Image,
  Link,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from '@chakra-ui/react';
import _ from 'lodash';
import { ChevronRightIcon } from '@chakra-ui/icons';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import {
  Link as RouterLink,
  useLocation,
  useHistory,
} from 'react-router-dom';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import Message from 'web-react-ui/src/components/collections/Message';
import Metrics from 'web-react-ui/src/components/metrics/Metrics';
import MetricsLoyalty from 'web-react-ui/src/components/metrics/MetricsLoyalty';
import TimeRangeSelector from 'web-react-ui/src/components/metrics/TimeRangeSelector';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';
import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import client from '../../services/client';
import propertyResolver from '../../services/propertyResolver';
import MyLocationSelector from '../offers/MyLocationsSelector';
import MetricsLoyaltyTotalWrapper from '../stats/MetricsLoyaltyTotalWrapper';
import businessesModule from '../../modules/businesses';
import discountCoupon from '../../components/offers/discount-coupon.svg';

import { strings } from '../../i18n';

const PerformanceOverview = ({
  category,
  businessId,
}: {
  category: 'promotions' | 'loyalty';
  businessId: string;
}): JSX.Element => {
  const [days, setDays] = useState(30);
  const [locationId, setLocationId] = useState('');
  const location = useLocation();

  const navigateBack = (breadcrumbs, businessIx, index) => {
    const basePath = `/business/${businessIx}`;
    const path = breadcrumbs.slice(0, index + 1).join('/');
    return `${basePath}/${path}`;
  };
  const history = useHistory();
  const breadcrumbs = location.pathname.split('/').splice(3);
  const fetchStats = () => {
    if (locationId === undefined) return null;
    const property = propertyResolver();

    const statsRepo = client.properties.for(property.id).businesses.for(business.id).locations.for(locationId).stats;

    if (category === 'loyalty') {
      return statsRepo.getLastNDays({ locationId, days });
    }
    return statsRepo.getLoyaltyLastNDays({ locationId, days });
  };
  const business = useSelector((state: RootStateOrAny) => businessesModule.selectors.business.getData(state));
  if (!business.hasPromotions) {
    return (
      <Box maxW="7xl" mx="auto">
        <ViewHeader title={'Promotions'} />
        <Flex flexDir="column" textAlign="center" align="center" h="100%" justify="center" gap={8}>
          <Image src={discountCoupon} w="192px" h="192px" />
          <Heading>{strings('dashboard.component.offerCta.promotions.heading')}</Heading>
          <Text fontSize="xl" maxWidth="35em">
            {strings('dashboard.component.offerCta.promotions.body')}
          </Text>
          <Button as={RouterLink} to={'./promotions/create'} colorScheme="blue" size="lg">
            {strings('dashboard.component.offerCta.promotions.buttonLabel')}
          </Button>
          <Text fontSize="xl" maxWidth="35em">
            {/* Wrapping Link onto a new line screws up the spacing when rendered */}
            <Trans i18nKey={'dashboard.component.offerCta.promotions.learnMoreLabel'}>
              Learn how{' '}
              <Link textDecoration="underline" href="https://getintheloop.ca/business/promotions" isExternal>
                promotional offers
              </Link>{' '}
              help your business reach its full potential.
            </Trans>
          </Text>
        </Flex>
      </Box>
    );
  }
  return (
    <Box>
      <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
        {breadcrumbs.map((itemName, ix) => (
          <BreadcrumbItem key={ix} isCurrentPage={ix === breadcrumbs.length - 1}>
            <BreadcrumbLink href={navigateBack(breadcrumbs, businessId, ix)}>{_.capitalize(itemName)}</BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <ViewHeader title="Performance" />
      <Box>
        <Flex ml="auto" flexWrap="wrap">
          <Box w="50%" />
          <Flex flexGrow={1} flexWrap="wrap">
            <Box flexGrow={1} m={4} minW="200px">
              <MyLocationSelector
                onChange={(newLocationId: string) => setLocationId(newLocationId)}
                value={locationId}
              />
            </Box>
            <Box flexGrow={1} mt={8} m={4} minW="200px">
              <TimeRangeSelector
                days={days}
                handleSetRange={({ days: newDays }: { days: number }) => setDays(newDays)}
              />
            </Box>
          </Flex>
        </Flex>
        <Request request={fetchStats} params={{ days, locationId }}>
          {({
            data,
            loading,
            error,
          }: {
            data: any;
            loading: boolean;
            error: { message: string };
          }) => {
            const stats = data || [];
            return (
              <WaitFor waitFor={!loading} wrapContents>
                {error && <Message error>{error.message}</Message>}
                {category === 'promotions' && (
                  <>
                    <h2>{strings('dashboard.screen.overview.total')}</h2>
                    <Metrics data={stats} plansHref="./plans" />
                  </>
                )}
                {category === 'loyalty' && (
                  <>
                    <h2>{strings('dashboard.screen.overview.loyalty')}</h2>
                    {/* @ts-ignore */}
                    <MetricsLoyaltyTotalWrapper businessId={businessId} locationId={locationId} />
                    <MetricsLoyalty data={stats} plansHref="./plans" />
                  </>
                )}
              </WaitFor>
            );
          }}
        </Request>
      </Box>
    </Box>
  );
};

export default PerformanceOverview;
