import React from "react";
import PropTypes from "prop-types";
import { useI18Next } from "shared/src/components/contexts/I18NextContext";
import { Menu, MenuButton, MenuList, MenuItem, Text } from "@chakra-ui/react";

const WidgetTimeRangeSelector = ({ setDays, days }) => {
  const { strings } = useI18Next();

  const options = [
    {
      key: "7D",
      value: 7,
      text: strings("ui.component.timeRangeSelector.7D"),
    },
    {
      key: "30D",
      value: 30,
      text: strings("ui.component.timeRangeSelector.30D"),
    },
    {
      key: "60D",
      value: 60,
      text: strings("ui.component.timeRangeSelector.60D"),
    },
    {
      key: "90D",
      value: 90,
      text: strings("ui.component.timeRangeSelector.90D"),
    },
    {
      key: "AllTimeD",
      value: Infinity,
      text: strings("ui.component.timeRangeSelector.364D"),
    },
  ];

  return (
    <div>
      <Menu>
        <MenuButton as={Text} textColor="blue.600" fontWeight="normal">
          {days !== Infinity ? `Last ${days} Days` : "All Time"}
        </MenuButton>
        <MenuList zIndex={10}>
          {options.map((time, ix) => {
            return (
              <MenuItem
                id={ix}
                onClick={(e) => setDays(time.value)}
                data={time}
              >
                {time.value !== Infinity ? `${time.value} Days` : "All time"}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
};

WidgetTimeRangeSelector.propTypes = {
  handleSetRange: PropTypes.func,
  days: PropTypes.number,
};

export default WidgetTimeRangeSelector;
