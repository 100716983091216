import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Link, Text, StatGroup } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import { useI18Next } from "shared/src/components/contexts/I18NextContext";
import Message from "web-react-ui/src/components/collections/Message";
import WidgetTimeRangeSelector from "web-react-ui/src/components/metrics/WidgetTimeRangeSelector";
import WaitFor from "web-react-ui/src/data/WaitFor";
import Request from "web-react-ui/src/data/Request";
import client from "business-dashboard/src/services/client.js";
import propertyResolver from "business-dashboard/src/services/propertyResolver.js";
import MetricsLoyaltyTotalWrapper from "business-dashboard/src/components/stats/MetricsLoyaltyTotalWrapper";
import "./Metrics.css";
import LoyaltyMetrics from "./LoyaltyMetrics";

const LoyaltyMetricsWidget = ({ plansHref, businessId, locationId }) => {
  const { strings } = useI18Next();

  const [days, setDays] = useState(30);

  const fetchStats = () => {
    if (locationId === undefined) return null;
    const property = propertyResolver();

    const statsRepo = client.properties
      .for(property.id)
      .businesses.for(businessId)
      .locations.for(locationId).stats;

    return Promise.all([
      statsRepo.getLoyaltyLastNDays({ locationId, days }),
    ]);
  };

  const theme = extendTheme({
    components: {
      Stat: {
        parts: ["container", "label", "number", "helpText", "icon"],
        baseStyle: {
          container: {
            borderRadius: "md",
            m: 4,
          },
          label: {
            fontWeight: "semibold",
            color: "gray.600",
          },
          number: {
            fontSize: "3rem !important",
            fontWeight: "semibold",
            color: "gray.900",
          },
          helpText: {
            fontSize: "md",
            color: "gray.500",
          },
        },
        sizes: {
          md: {
            number: {
              fontSize: "2.5rem !important",
            },
            label: {
              fontSize: "1rem !important",
            },
          },
        },
        variants: {},
        defaultProps: {},
      },
    },
  });

  return (
    <Request request={fetchStats} params={{ days, locationId }}>
      {({ data, loading, error }) => {
        const [loyaltyStats] = data || [];
        const restrictions = (loyaltyStats && loyaltyStats.restrictions) || {};
        return (
          <WaitFor waitFor={!loading} wrapContents>
            {error && <Message error>{error.message}</Message>}
            <ChakraProvider theme={theme}>
              <Flex align="center" gap="4" wrap="nowrap" justify="center">
                {restrictions.isRestricted ? (
                  <MetricsUpgradeBanner plansHref={plansHref}>
                    {strings(
                      "ui.component.metricsLoyalty.metricsUpgradeBanner"
                    )}
                  </MetricsUpgradeBanner>
                ) : null}
                <MetricsLoyaltyTotalWrapper
                  businessId={businessId}
                  locationId={locationId}
                  minimizedUI
                />
                <LoyaltyMetrics
                  strings={strings}
                  data={loyaltyStats}
                  plansHref="./plans"
                />
              </Flex>
            </ChakraProvider>
            <Flex gap="2" justify="end" pt="2" pb="4">
              <WidgetTimeRangeSelector
                setDays={(val) => setDays(val)}
                days={days}
              />
              <Link as={RouterLink} to="loyalty/performance">
                <Text textColor="blue.600" textDecoration="underline">
                  See Detailed Stats
                </Text>
              </Link>
            </Flex>
          </WaitFor>
        );
      }}
    </Request>
  );
};

LoyaltyMetricsWidget.propTypes = {
  data: PropTypes.object,
  plansHref: PropTypes.string,
};

export default LoyaltyMetricsWidget;
