import React from 'react';
import PropTypes from 'prop-types';
import usePermissions from 'shared/src/hooks/usePermissions';
import MetricsLoyaltyTotal from 'web-react-ui/src/components/metrics/MetricsLoyaltyTotal';
import LoyaltyMetricsTotal from 'web-react-ui/src/components/metrics/LoyaltyMetricsTotal';
import useProperty from '../useProperty';

const MetricsLoyaltyTotalWrapper = React.memo(
  ({ businessId, offerId, locationId, minimizedUI = false }) => {
    const property = useProperty();
    const canGetBusinessStats = usePermissions(
      { propertyId: property.id, businessId },
      ['property.business.stats.uniques.loyalty.get']
    );

    const userNoPermission = !locationId && canGetBusinessStats === false;
    if (minimizedUI) {
      return (
        <LoyaltyMetricsTotal
          userNoPermission={userNoPermission}
          propertyId={property.id}
          businessId={businessId}
          offerId={offerId}
          locationId={locationId}
        />
      );
    }
    return (
      <MetricsLoyaltyTotal
        userNoPermission={userNoPermission}
        propertyId={property.id}
        businessId={businessId}
        offerId={offerId}
        locationId={locationId}
      />
    );
  }
);

MetricsLoyaltyTotalWrapper.propTypes = {
  businessId: PropTypes.string,
  offerId: PropTypes.string,
  locationId: PropTypes.string,
};

MetricsLoyaltyTotalWrapper.displayName = 'MetricsLoyaltyTotalWrapper';

export default MetricsLoyaltyTotalWrapper;
