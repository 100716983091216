/* eslint no-nested-ternary: 0 */
import PropTypes from "prop-types";
import React from "react";
import numbersLargeBlurred from "../../assets/stats/Numbers_Large_Blurred.png";
import {
  Box,
  Image,
  Stat,
  StatLabel,
  StatNumber,
  Tooltip,
  Stack,
} from "@chakra-ui/react";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import "./Metrics.css";

const WidgetStat = ({ label, explanationLabel, data, isLast, restricted }) => (
  <Stat>
    <Stack align="center" direction="row" spacing={2}>
      <StatLabel>{label}</StatLabel>
      <Tooltip hasArrow arrowSize={15} openDelay={400} label={explanationLabel}>
        <QuestionOutlineIcon color="gray.400" />
      </Tooltip>
    </Stack>
    {restricted && (
      <Image src={numbersLargeBlurred} height="5rem" alt="Data restricted" />
    )}
    {!restricted && (
      <StatNumber>
        {data.total !== undefined ? Math.ceil(data.total) : "––"}
      </StatNumber>
    )}
  </Stat>
);

WidgetStat.propTypes = {
  label: PropTypes.string,
  explanationLabel: PropTypes.string,
  data: PropTypes.obj,
  isLast: PropTypes.bool,
  restricted: PropTypes.bool,
};

export default WidgetStat;
