import React from "react";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import numbersLargeBlurred from "../../assets/stats/Numbers_Large_Blurred.png";
import PropTypes from "prop-types";
import {
  Box,
  Image,
  Divider,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Tooltip,
  Stack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { useI18Next } from "shared/src/components/contexts/I18NextContext";
import "./Metrics.css";
import MetricsUpgradeBanner from "./MetricsUpgradeBanner";

const theme = extendTheme({
  components: {
    Stat: {
      parts: ["container", "label", "number", "helpText", "icon"],
      baseStyle: {
        container: {
          borderRadius: "md",
          m: 4,
        },
        label: {
          fontWeight: "semibold",
          color: "gray.600",
        },
        number: {
          fontSize: "3rem !important",
          fontWeight: "semibold",
          color: "gray.900",
        },
        helpText: {
          fontSize: "md",
          color: "gray.500",
        },
      },
      sizes: {
        md: {
          number: {
            fontSize: "2.5rem !important",
          },
          label: {
            fontSize: "1rem !important",
          },
        },
      },
      variants: {},
      defaultProps: {},
    },
  },
});

const Metrics = ({ data, plansHref }) => {
  const { strings } = useI18Next();

  const reach = {
    total: data && data.reach && data.reach.total,
    age: data && data.reach && data.reach.ageSeries,
    gender: data && data.reach && data.reach.genderBreakdown,
  };
  const response = {
    total: data && data.response && data.response.total,
    age: data && data.response && data.response.ageSeries,
    gender: data && data.response && data.response.genderBreakdown,
  };
  const action = {
    total: data && data.action && data.action.total,
    age: data && data.action && data.action.ageSeries,
    gender: data && data.action && data.action.genderBreakdown,
  };
  const restrictions = (data && data.restrictions) || {};
  const label = {
    reach: strings("ui.component.metrics.reach"),
    reachExplanation: strings("ui.component.metrics.reachExplanation"),
    response: strings("ui.component.metrics.response"),
    responseExplanation: strings("ui.component.metrics.responseExplanation"),
    action: strings("ui.component.metrics.action"),
    actionExplanation: strings("ui.component.metrics.actionExplanation"),
  };
  const reachRestricted =
    restrictions["stats.feature.general.core.include_reach"];
  const responseRestricted =
    restrictions["stats.feature.general.core.include_response"];
  const actionRestricted =
    restrictions["stats.feature.general.core.include_action"];
  return (
    <Box>
      {restrictions.isRestricted ? (
        <MetricsUpgradeBanner plansHref={plansHref} />
      ) : null}
      <StatGroup
        height="8rem"
        style={{ border: "1px solid #e4e4e7", borderRadius: "0.5rem" }}
      >
        <ChakraProvider theme={theme}>
          <Stat>
            <Stack align="center" direction="row" spacing={2}>
              <StatLabel>Reach</StatLabel>
              <Tooltip
                hasArrow
                arrowSize={15}
                openDelay={400}
                label={label.reachExplanation}
              >
                <QuestionOutlineIcon color="gray.400" />
              </Tooltip>
            </Stack>
            {reachRestricted && (
              <Image
                src={numbersLargeBlurred}
                height="5rem"
                alt="Data restricted"
              />
            )}
            {!reachRestricted && (
              <StatNumber>
                {reach.total !== undefined ? Math.ceil(reach.total) : "––"}
              </StatNumber>
            )}
          </Stat>
          <Divider
            orientation="vertical"
            style={{ height: "80%", margin: "auto" }}
          />
          <Stat>
            <Stack align="center" direction="row" spacing={2}>
              <StatLabel>Response</StatLabel>
              <Tooltip
                hasArrow
                arrowSize={15}
                openDelay={400}
                label={label.responseExplanation}
              >
                <QuestionOutlineIcon color="gray.400" />
              </Tooltip>
            </Stack>
            {responseRestricted && (
              <Image
                src={numbersLargeBlurred}
                height="5rem"
                alt="Data restricted"
              />
            )}
            {!responseRestricted && (
              <StatNumber>
                {response.total !== undefined
                  ? Math.ceil(response.total)
                  : "––"}
              </StatNumber>
            )}
          </Stat>
          <Divider
            orientation="vertical"
            style={{ height: "80%", margin: "auto" }}
          />
          <Stat>
            <Stack align="center" direction="row" spacing={2}>
              <StatLabel>Actions</StatLabel>
              <Tooltip
                hasArrow
                arrowSize={15}
                openDelay={400}
                label={label.actionExplanation}
              >
                <QuestionOutlineIcon color="gray.400" />
              </Tooltip>
            </Stack>
            {actionRestricted && (
              <Image
                src={numbersLargeBlurred}
                height="5rem"
                alt="Data restricted"
              />
            )}
            {!actionRestricted && (
              <StatNumber>
                {action.total !== undefined ? Math.ceil(action.total) : "––"}
              </StatNumber>
            )}
          </Stat>
        </ChakraProvider>
      </StatGroup>
    </Box>
  );
};

Metrics.propTypes = {
  data: PropTypes.object,
  plansHref: PropTypes.string,
};

export default Metrics;
