import React, { useState } from 'react';
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import PromoMetrics from 'web-react-ui/src/components/metrics/PromoMetrics';
import Message from 'web-react-ui/src/components/collections/Message';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import Request from 'web-react-ui/src/data/Request';
import WidgetTimeRangeSelector from 'web-react-ui/src/components/metrics/WidgetTimeRangeSelector';
import client from '../../services/client';
import propertyResolver from '../../services/propertyResolver';

const PromoMetricsWidget = ({
  businessId,
  locationId,
}: {
  businessId: string;
  locationId: string;
}): JSX.Element => {
  const [days, setDays] = useState(30);
  const fetchStats = () => {
    const property = propertyResolver();
    const statsRepo = client.properties
      .for(property.id)
      .businesses.for(businessId)
      .locations.for(locationId).stats;
    return Promise.all([
      statsRepo.getLastNDays({ locationId, days })
    ]);
  };

  return (
    <Box>
      <Request request={fetchStats} params={{ days, locationId }}>
        {({
          data,
          loading,
          error,
        }: {
          data: [stats: any];
          loading: boolean;
          error: { message: string };
        }) => {
          const [stats] = data || [];
          return (
            <WaitFor waitFor={!loading} wrapContents>
              {error && <Message error>{error.message}</Message>}
              <PromoMetrics data={stats} plansHref="./plans" />
              <Flex gap="2" justify="end" pt="2" pb="4">
                <WidgetTimeRangeSelector
                  setDays={(val: number) => setDays(val)}
                  days={days}
                />
                <Link as={RouterLink} to="promotions/performance">
                  <Text textColor="blue.600" textDecoration="underline">
                    See Detailed Stats
                  </Text>
                </Link>
              </Flex>
            </WaitFor>
          );
        }}
      </Request>
    </Box>
  );
};

export default PromoMetricsWidget;
