import React from "react";
import { Divider, StatGroup } from "@chakra-ui/react";
import WidgetStat from "./WidgetStat";

const LoyaltyMetrics = ({ data, strings }) => {
  const reach = {
    total: data && data.reach && data.reach.total,
    age: data && data.reach && data.reach.ageSeries,
    gender: data && data.reach && data.reach.genderBreakdown,
  };
  const response = {
    total: data && data.response && data.response.total,
    age: data && data.response && data.response.ageSeries,
    gender: data && data.response && data.response.genderBreakdown,
  };
  const action = {
    total: data && data.action && data.action.total,
    age: data && data.action && data.action.ageSeries,
    gender: data && data.action && data.action.genderBreakdown,
  };
  const earned = {
    total: data && data.earned && data.earned.total,
    age: data && data.earned && data.earned.ageSeries,
    gender: data && data.earned && data.earned.genderBreakdown,
  };
  const restrictions = (data && data.restrictions) || {};

  const reachRestricted =
    restrictions["stats.feature.general.loyalty.include_reach"];
  const responseRestricted =
    restrictions["stats.feature.general.loyalty.include_response"];
  const actionRestricted =
    restrictions["stats.feature.general.loyalty.include_action"];
  const earnedRestricted =
    restrictions["stats.feature.general.loyalty.include_earned"];

  const label = {
    reach: strings("ui.component.metrics.reach"),
    reachExplanation: strings("ui.component.metrics.reachExplanation"),
    response: strings("ui.component.metrics.response"),
    responseExplanation: strings("ui.component.metrics.responseExplanation"),
    action: strings("ui.component.metrics.action"),
    actionExplanation: strings("ui.component.metrics.actionExplanation"),
    earned: strings("ui.component.metricsLoyalty.earned"),
    earnedExplanation: strings("ui.component.metricsLoyalty.earnedExplanation"),
  };

  return (
    <StatGroup
      height="8rem"
      flexGrow="3"
      style={{
        border: "1px solid #e4e4e7",
        borderRadius: "0.5rem",
      }}
    >
      <WidgetStat
        label="Reach"
        explanationLabel={label.reachExplanation}
        data={reach}
        restricted={reachRestricted}
      />
      <Divider
        orientation="vertical"
        style={{ height: "80%", margin: "auto" }}
      />
      <WidgetStat
        label="Response"
        explanationLabel={label.responseExplanation}
        data={response}
        restricted={responseRestricted}
      />
      <Divider
        orientation="vertical"
        style={{ height: "80%", margin: "auto" }}
      />
      <WidgetStat
        label="Action"
        explanationLabel={label.actionExplanation}
        data={action}
        restricted={actionRestricted}
      />
      <Divider
        orientation="vertical"
        style={{ height: "80%", margin: "auto" }}
      />
      <WidgetStat
        label="Earned"
        explanationLabel={label.earnedExplanation}
        data={earned}
        isLast
        restricted={earnedRestricted}
      />
    </StatGroup>
  );
};

export default LoyaltyMetrics;
