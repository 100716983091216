/* eslint no-nested-ternary: 0 */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Image,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Tooltip,
  Stack,
} from "@chakra-ui/react";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import usePromise from "shared/src/hooks/usePromise";
import ClientContext from "shared/src/components/contexts/ClientContext";
import { useI18Next } from "shared/src/components/contexts/I18NextContext";

import Label from "../elements/Label";
import Icon from "../elements/Icon";

import numbersLargeBlurred from "../../assets/stats/Numbers_Large_Blurred.png";

import "./Metrics.css";
const LoyaltyMetricsTotalView = ({ data, isPermitted }) => {
  const { strings } = useI18Next();

  const renderContent = (isRestricted, metrics) => {
    if (!isPermitted) {
      // User has NO permissions
      return (
        <div className="flex aic jcc mb3">
          <div className="tac metrics-funnel__label tooltip">
            <Label className="tooltiptext">
              {strings("ui.component.metricsLoyaltyTotalView.toolTipText1")}
            </Label>
            <span className="text--muted">
              <Icon className="eye slash outline grey left" />
              <em>
                {strings(
                  "ui.component.metricsLoyaltyTotalView.requiresBusinessRole"
                )}
              </em>
            </span>
          </div>
        </div>
      );
    }
    if (isRestricted) {
      // User has permissions and isRestricted is true
      return (
        <img
          className="metrics-funnel__placeholder-number mla mra"
          src={numbersLargeBlurred}
        />
      );
    }

    if (metrics) {
      // User has permissions, isRestricted is false, and there's a number
      return <StatNumber textAlign="center">{Math.ceil(metrics)}</StatNumber>;
    }
    return <StatNumber textAlign="center">0</StatNumber>;
  };

  const { count, restrictions } = data || {};

  const restricted =
    restrictions && restrictions["stats.feature.unique.loyalty.include"];

  return (
    <StatGroup
      width="16rem"
      height="8rem"
      style={{ border: "1px solid #e4e4e7", borderRadius: "0.5rem" }}
      backgroundColor="gray.50"
    >
      <Stat>
        <Stack align="center" direction="row" spacing={2}>
          <StatLabel>Total Loyalty Members</StatLabel>
          <Tooltip
            hasArrow
            arrowSize={15}
            openDelay={400}
            label={strings("ui.component.metricsLoyaltyTotalView.toolTipText2")}
          >
            <QuestionOutlineIcon color="gray.600" />
          </Tooltip>
        </Stack>
        {restricted && (
          <Image
            src={numbersLargeBlurred}
            height="5rem"
            alt="Data restricted"
          />
        )}
        {!restricted && (
          <StatNumber>{renderContent(restricted, count)}</StatNumber>
        )}
      </Stat>
    </StatGroup>
  );
};

LoyaltyMetricsTotalView.propTypes = {
  data: PropTypes.object,
  isPermitted: PropTypes.bool,
};

const LoyaltyMetricsTotal = ({
  propertyId,
  businessId,
  locationId,
  offerId,
  userNoPermission,
}) => {
  const client = useContext(ClientContext);
  const [, , stats] = usePromise(
    () => {
      if (userNoPermission) return null;
      if (!client) return null;
      let statsRepo = client.properties.for(propertyId);
      if (businessId) statsRepo = statsRepo.businesses.for(businessId);
      if (locationId) statsRepo = statsRepo.locations.for(locationId);
      if (offerId) statsRepo = statsRepo.offers.for(offerId);
      statsRepo = statsRepo.stats;

      return statsRepo ? statsRepo.getLoyaltyUniques() : null;
    },
    [client, propertyId, businessId, locationId, offerId, userNoPermission],
    null,
    true
  );

  return (
    <LoyaltyMetricsTotalView data={stats} isPermitted={!userNoPermission} />
  );
};

LoyaltyMetricsTotal.propTypes = {
  propertyId: PropTypes.String,
  businessId: PropTypes.String,
  locationId: PropTypes.String,
  offerId: PropTypes.String,
  userNoPermission: PropTypes.bool,
};

export { LoyaltyMetricsTotalView };
export default LoyaltyMetricsTotal;
